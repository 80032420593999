import { createNexityTheme } from '@styles/nexity';

export default createNexityTheme({
  palette: {
    primary: {
      main: '#112647',
    },
    success: {
      main: '#75cbbf',
    },
    chipSuccess: {
      main: '#F4FBEE',
      contrastText: '#2e7a70',
    },
  },
});
